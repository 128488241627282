import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { InternalLoan } from '../../../api/models/InternalLoan';
import { InternalLoanPayment, defaultInternalLoanPaymentValues } from '../../../api/models/InternalLoanPayment';
import { createInternalLoanPayment, getInternalLoan } from '../../../api/personnel';
import routes from '../../../common/routes';
import { formatNumber, safeNumber } from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';
import Spinner from '../../shared/Spinner';
import { RightText } from '../../shared/StyledText';


const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Préstamos internos',
  url: routes.personnel.internal_loans.list,
}];

const InternalLoanPaymentForm: React.FC = () => {
  const methods = useForm<InternalLoanPayment>({ defaultValues: defaultInternalLoanPaymentValues });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isLoading = useRef<boolean>(false);
  const [internalLoan, setInternalLoan] = useState<InternalLoan>({} as InternalLoan);
  const amount = useWatch({ control: methods.control, name: 'amount' });

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getInternalLoan(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setInternalLoan(data);
      methods.setValue('internal_loan_id', data.id!);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [recordId, enqueueSnackbar, methods]);

  useEffect(() => {
    if (!isLoading.current) {
      fetch();
    }
  }, [isLoading, fetch]);

  const onSubmit: SubmitHandler<InternalLoanPayment> = async record => {
    const { error } = await createInternalLoanPayment(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.personnel.internal_loans.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={'Abono a préstamo interno'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Abono a préstamo interno">
            { isLoading.current || (!internalLoan?.id && !isLoading.current) ? (
              <Spinner />
            ) : (
              <>
                <SectionDivider>
                  <Typography component="h6" variant="h6">{internalLoan?.applicant_name ?? ''}</Typography>
                </SectionDivider>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      Balance actual
                      <Typography
                        variant="h6"
                        display="block"
                        gutterBottom
                      >{formatNumber('money', safeNumber(internalLoan.pending_balance, 0))}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RightText>
                      <Typography>
                        Nuevo balance
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                        >{formatNumber('money', safeNumber(internalLoan.pending_balance, 0) - safeNumber(amount, 0))}</Typography>
                      </Typography>
                    </RightText>
                  </Grid>
                </Grid>
                <SectionDivider>
                  <Typography component="h6" variant="h6">Abono</Typography>
                </SectionDivider>
                <FormTextField name="amount" label="Monto" />
                <FormTextAreaField name="comments" label="Comentarios" />
              </>
            )}
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
                Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
                Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default InternalLoanPaymentForm;
