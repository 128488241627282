import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

import { getCashTransactionHistory } from '../../../api/admin';
import { CashTransactionHistory } from '../../../api/models/Cash';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import DateColumn from '../../shared/list_view_column_renderers/DateColumn';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

// const ActionsWrapper = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     padding-top: 1em;
// `;

const bcHistory = [{
  label: 'Cajas',
  url: routes.admin.cashes.list,
}];

const TransactionHistory: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  // const [cash, setCash] = useState<Cash>({} as Cash);
  const [transactionHistory, setTransactionHistory] = useState<CashTransactionHistory[]>([]);
  const tableHeaders = ['Fecha', 'Descripción', <AlignedColumn align="right" value="Monto" />, <AlignedColumn align="right" value="Balance" />];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getCashTransactionHistory(currentPage, perPage, searchValue === '' ? undefined : searchValue, undefined, { id: recordId! });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setTransactionHistory(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, searchValue, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      navigate(getUrlWithPagination(routes.admin.cashes.transaction_history.replace(':id', recordId?.toString() ?? ''), currentPage, perPage), { replace: true });
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, recordId]);

  useEffect(() => {
    if (transactionHistory.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, transactionHistory.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'date', 'description', 'amount', 'end_balance'] as Array<keyof CashTransactionHistory>;
    const renderers: RenderersListType[] = [
      {
        field: 'date',
        renderer: value => <DateColumn value={value} displayFormat="display_date" />,
      },
      {
        field: 'amount',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: 'end_balance',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
    ];
    return getTableRows<CashTransactionHistory>(transactionHistory, objectFields, renderers);
  }, [transactionHistory]);

  return (
    <MainLayout>
      <BreadCrumbs currentPage="Historial de transacciones" history={bcHistory} />
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Caja"
            formPageUrl={''}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            hasActions
            noAddButton
            selectable={false}
            onRowSelect={rowId => console.log(rowId)}
            searchPlaceholder="Nombre"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </MainLayout>
  );
};

export default TransactionHistory;
