import React from 'react';


import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import PrintIcon from '@material-ui/icons/Print';
import styled from 'styled-components';

// import { BaseModel } from '../../../api/models/BaeModel';
import { BaseModel } from '../../../api/models/BaeModel';
import { Consumer } from '../../../common/types';


const Wrapper = styled.div`
  color: unset;
  text-decoration: none;
`;

interface ActionProps {
  label: string;
  onClick: Consumer<BaseModel>
}

interface Props  {
  actions: ActionProps[];
  record: string | number;
}
const ListActionsColumn: React.FC<Props> = ({ actions, record }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const recordObject = JSON.parse(record.toString()) as BaseModel;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}><MoreHorizIcon /></IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        {actions.map((action, idx) => (
          <MenuItem onClick={() => action.onClick(recordObject)} key={`action_column_${idx}`}>
            {anchorEl && (
              <Wrapper>
                {action.label}
              </Wrapper>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ListActionsColumn;
