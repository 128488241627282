import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { loginRequest } from '../../api/auth';
import YeyosLogo from '../../assets/images/dcredito-logo-transformed-transformed.png';
import {  THEME } from '../../common/constants';
import { useAuth } from '../../contexts/useAuth';
import useDeviceDetect from '../../hooks/useDeviceDetect';

const Root = styled.div<{isTouchDevice: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isTouchDevice ? 'flex-start' : 'center'};
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background-color: ${THEME.uiElements.background};
`;

const Container = styled.div<{isTouchDevice: boolean}>`
  display: flex;
  flex-direction: ${props => props.isTouchDevice ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
`;

const GraphicsWrapper = styled(Paper)`
  z-index: 2;
  display: flex;
  overflow: hidden;
`;

const GraphicsContainer = styled.div<{isTouchDevice: boolean}>`
  width: ${props => props.isTouchDevice ? '100%' : '15vw'};
  height: 100%;
  position: relative;
  background: rgb(26, 9, 49);
  background: linear-gradient(304deg, rgb(189, 180, 203) 0%, rgb(242, 236, 250) 100%);
  padding: 1em;
`;

const FormWrapper = styled.div<{isTouchDevice: boolean}>`
  padding: ${props => props.isTouchDevice ? '0 0.75em' : '0.75em 0'};
  margin-left: -3px;
  display: flex;
`;

const FormContainer = styled.div`
  padding: 2em 2em 2em calc(2em + 3px);
  min-width: 300px;
  width: 20vw;
`;

const InputWrapper = styled.div`
  padding: 1em 0;
  width: 100%;
`;

const YeyosLogoImage = styled.img`
  width: 100%;
`;

const Login: React.FC = () => {
  const { setToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isTouchDevice = useDeviceDetect();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const from = location.state?.from?.pathname || '/';

  const requestLogin = async () => {
    setIsLoading(true);
    const { token, error } = await loginRequest(email, password);
    setIsLoading(false);
    if (error) {
      console.log(error);
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
    setToken(token ?? '');
    navigate(from, { replace: true });
  };

  const handleOnLoginClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    requestLogin();
  };

  return (
    <Root isTouchDevice={isTouchDevice}>
      <Container isTouchDevice={isTouchDevice}>
        <GraphicsWrapper elevation={3}>
          <GraphicsContainer isTouchDevice={isTouchDevice}>
            {/*<YeyosBGImage src={YeyosBG} alt="yeyos-bg"  />*/}
            <YeyosLogoImage src={YeyosLogo} />
          </GraphicsContainer>
        </GraphicsWrapper>
        <FormWrapper isTouchDevice={isTouchDevice}>
          <Paper elevation={3} style={{ alignSelf: 'center', display: 'flex', justifySelf: 'center' }}>
            <FormContainer>
              <InputWrapper>
                <TextField
                  label="Email"
                  placeholder="your.email@sample.com"
                  value={email}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  fullWidth
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  label="Password"
                  value={password}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      requestLogin();
                    }
                  }}
                  type="password"
                  fullWidth
                />
              </InputWrapper>
              <InputWrapper>
                { isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOnLoginClick}
                  >
                  Iniciar Sesión
                  </Button>
                )}
              </InputWrapper>
            </FormContainer>
          </Paper>
        </FormWrapper>
      </Container>
    </Root>
  );
};

export default Login;
