import React, { useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import FormTextField from './FormTextField';
import { BillCountHelper, BillCountHelperDetail } from '../../api/models/BillCountHelper';
import { formatNumber } from '../../common/utils';

const CountHelperDetailTotal = styled.div`
  font-weight: bold;
  text-align: right;
  margin-top: 20px;
`;

interface Props {
  fieldName: string;
  // eslint-disable-next-line react/require-default-props
  bch?: BillCountHelper;
  // eslint-disable-next-line react/require-default-props
  readOnly?: boolean;
}

const FormBillCountHelperFieldGroup: React.FC<Props> = ({
  fieldName, bch, readOnly = false,
}) => {

  const getBCHDValue = useCallback((bchd: BillCountHelperDetail) => {
    return bchd.value * bchd.count * (bchd.is_usd ? bch?.usd_exchange_rate ?? 1 : 1);
  }, [bch]);

  return (
    <>
      {bch?.bill_count_helper_details?.map((bchd, idx) => (
        <>
          <Grid item xs={6} md={8}>
            <FormTextField
              name={`${fieldName}.bill_count_helper_details.${idx}.count`}
              label={bchd.name}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CountHelperDetailTotal>
              <Typography variant="body1">{formatNumber('money', getBCHDValue(bchd))}</Typography>
            </CountHelperDetailTotal>
          </Grid>
        </>
      ))}
    </>
  );
};

export default FormBillCountHelperFieldGroup;
