import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import {
  CreateOrUpdateOneRequestType, GetListRequestType, GetOneRequestType, ResponseWithPagination,
} from './models/BaeModel';
import {
  Cash, CashDepositFormRequest, CashTransactionHistory, CashTransferFormRequest, CashWithdrawalFormRequest,
} from './models/Cash';
import { Employee } from './models/Employee';
import { InvestmentAccount } from './models/InvestmentAccount';
import { Salesman } from './models/Salesman';
import { SalesmanReconciliation } from './models/SalesmanReconciliation';
import { User } from './models/User';
import { getUrlWithPagination, ADMIN_ROUTES } from './routes';

// >> User
const createUser: CreateOrUpdateOneRequestType<User> = async record => {
  try {
    const response = await api.post<User>(ADMIN_ROUTES.users.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateUser: CreateOrUpdateOneRequestType<User> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<User>(
      ADMIN_ROUTES.users.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getUserList: GetListRequestType<User> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<User[]>>(getUrlWithPagination(ADMIN_ROUTES.users.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getUser: GetOneRequestType<User> = async id => {
  try {
    const response = await api.get<User>(ADMIN_ROUTES.users.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << User

// Salesmen
const getSalesmenList: GetListRequestType<Salesman> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<Salesman[]>>(getUrlWithPagination(ADMIN_ROUTES.salesmen.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createSalesman: CreateOrUpdateOneRequestType<Salesman> = async record => {
  try {
    const response = await api.post<Salesman>(ADMIN_ROUTES.salesmen.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateSalesman: CreateOrUpdateOneRequestType<Salesman> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<Salesman>(
      ADMIN_ROUTES.salesmen.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getSalesman: GetOneRequestType<Salesman> = async (id, extraParams) => {
  try {
    // const response = await api.get<Salesman>(ADMIN_ROUTES.salesmen.single.replace(':id', id));
    const response = await api.get<Salesman>(
      getUrlWithPagination(
        ADMIN_ROUTES.salesmen.single.replace(':id', id),
        undefined,
        undefined,
        undefined,
        undefined,
        { ...extraParams },
      ),
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << Salesmen

// >> SalesmanReconciliation
const createSalesmanReconciliation: CreateOrUpdateOneRequestType<SalesmanReconciliation> = async record => {
  try {
    const response = await api.post<SalesmanReconciliation>(ADMIN_ROUTES.salesmanReconciliation.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateSalesmanReconciliation: CreateOrUpdateOneRequestType<SalesmanReconciliation> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<SalesmanReconciliation>(
      ADMIN_ROUTES.salesmanReconciliation.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};
// << SalesmanReconciliation

// Employees
const getEmployeeList: GetListRequestType<Employee> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<Employee[]>>(getUrlWithPagination(ADMIN_ROUTES.employees.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createEmployee: CreateOrUpdateOneRequestType<Employee> = async record => {
  try {
    const response = await api.post<Employee>(ADMIN_ROUTES.employees.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateEmployee: CreateOrUpdateOneRequestType<Employee> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<Employee>(
      ADMIN_ROUTES.employees.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getEmployee: GetOneRequestType<Employee> = async id => {
  try {
    const response = await api.get<Employee>(ADMIN_ROUTES.employees.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << Employees

// >> Cash
const createCash: CreateOrUpdateOneRequestType<Cash> = async record => {
  try {
    const response = await api.post<Cash>(ADMIN_ROUTES.cashes.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateCash: CreateOrUpdateOneRequestType<Cash> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<Cash>(
      ADMIN_ROUTES.cashes.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCashList: GetListRequestType<Cash> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<Cash[]>>(getUrlWithPagination(ADMIN_ROUTES.cashes.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCash: GetOneRequestType<Cash> = async id => {
  try {
    const response = await api.get<Cash>(ADMIN_ROUTES.cashes.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createCashDeposit: CreateOrUpdateOneRequestType<CashDepositFormRequest> = async record => {
  try {
    const response = await api.post<CashDepositFormRequest>(ADMIN_ROUTES.cashes.deposit.replace(':id', record.cash_id.toString()), record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createCashWithdrawal: CreateOrUpdateOneRequestType<CashWithdrawalFormRequest> = async record => {
  try {
    const response = await api.post<CashWithdrawalFormRequest>(ADMIN_ROUTES.cashes.withdrawal.replace(':id', record.cash_id.toString()), record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createCashTransfer: CreateOrUpdateOneRequestType<CashTransferFormRequest> = async record => {
  try {
    const response = await api.post<CashTransferFormRequest>(ADMIN_ROUTES.cashes.transfer.replace(':id', record.from_cash_id.toString()), record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCashTransactionHistory: GetListRequestType<CashTransactionHistory> = async (page, perPage, search, tenantId, extraParams) => {
  try {
    const response = await api.get<ResponseWithPagination<CashTransactionHistory[]>>(getUrlWithPagination(ADMIN_ROUTES.cashes.transaction_history.replace(':id', extraParams?.id), page, perPage, undefined, search, extraParams));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << Cash

// >> Cash
const createInvestmentAccount: CreateOrUpdateOneRequestType<InvestmentAccount> = async record => {
  try {
    const response = await api.post<InvestmentAccount>(ADMIN_ROUTES.investment_accounts.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateInvestmentAccount: CreateOrUpdateOneRequestType<InvestmentAccount> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<InvestmentAccount>(
      ADMIN_ROUTES.investment_accounts.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getInvestmentAccountList: GetListRequestType<InvestmentAccount> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<InvestmentAccount[]>>(getUrlWithPagination(ADMIN_ROUTES.investment_accounts.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getInvestmentAccount: GetOneRequestType<InvestmentAccount> = async id => {
  try {
    const response = await api.get<InvestmentAccount>(ADMIN_ROUTES.investment_accounts.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << InvestmentAccount

export {
  getUser,
  createUser,
  getUserList,
  updateUser,
  getSalesmenList,
  createSalesman,
  updateSalesman,
  getSalesman,
  createSalesmanReconciliation,
  updateSalesmanReconciliation,
  getEmployeeList,
  getEmployee,
  createEmployee,
  updateEmployee,
  createCash,
  updateCash,
  getCash,
  getCashList,
  createCashDeposit,
  createCashWithdrawal,
  createCashTransfer,
  getCashTransactionHistory,
  createInvestmentAccount,
  updateInvestmentAccount,
  getInvestmentAccountList,
  getInvestmentAccount,
};
