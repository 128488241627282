import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';

import { getCashList } from '../../../api/admin';
import { Cash } from '../../../api/models/Cash';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import ListActionsColumn from '../../shared/list_view_column_renderers/ListActionsColumn';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onCashSelect?: (cash?: Cash) => void;
}

const CashList: React.FC<Props> = ({ inModal, onCashSelect }) => {
  const [searchParams] = useSearchParams();
  const [cashes, setCashes] = useState<Cash[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['Nombre', <AlignedColumn align="right" value="Balance" />];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getCashList(currentPage, perPage, searchValue === '' ? undefined : searchValue);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setCashes(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, searchValue]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.admin.employees.list, currentPage, perPage), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal]);

  useEffect(() => {
    if (cashes.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, cashes.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'name', 'balance'] as Array<keyof Cash>;
    const renderers: RenderersListType[] = [
      {
        field: 'balance',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: '_actions',
        renderer: record => <ListActionsColumn actions={[
          {
            label: 'Registrar pago con depósito',
            onClick: record => navigate(routes.admin.cashes.deposit.replace(':id', record.id!.toString())),
          },
          {
            label: 'Transferencia entre cajas',
            onClick: record => navigate(routes.admin.cashes.transfer.replace(':id',  record.id!.toString())),
          },
          {
            label: 'Enviar dinero a gestor',
            onClick: record => navigate(routes.admin.cashes.withdrawal.replace(':id',  record.id!.toString())),
          },
          {
            label: 'Transacciones',
            onClick: record => navigate(routes.admin.cashes.transaction_history.replace(':id',  record.id!.toString())),
          },
        ]} record={record} />,
      },
    ];
    return getTableRows<Cash>(cashes, objectFields, renderers, true);
  }, [cashes, navigate]);

  const handleRowSelect = (rowId: string) => {
    if (!onCashSelect) {
      return;
    }

    onCashSelect(cashes.find(u => u?.id?.toString() === rowId.toString()));
  };

  const content = () => (
    <>
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Caja"
            formPageUrl={inModal ? '' : routes.admin.cashes.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            hasActions
            noAddButton={inModal}
            selectable={inModal}
            onRowSelect={rowId => handleRowSelect(rowId)}
            searchPlaceholder="Nombre"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </>
  );

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Caja" />
          {content()}
        </MainLayout>
      )}
    </>
  );
};

export default CashList;
