import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  getCash, getSalesmenList, createCashDeposit,
} from '../../../api/admin';
import { Cash, CashDepositFormRequest } from '../../../api/models/Cash';
import { PaymentShortInfo } from '../../../api/models/Payment';
import { getPersonShortName } from '../../../api/models/Person';
import { Salesman } from '../../../api/models/Salesman';
import { getTodayPaymentsForSalesman } from '../../../api/payments';
import routes from '../../../common/routes';
import {
  formatDate, formatNumber, safeNumber,
} from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormSelectField from '../../shared/FormSelectField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';
import Spinner from '../../shared/Spinner';
import { RightText } from '../../shared/StyledText';


const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Cajas',
  url: routes.admin.cashes.list,
}];

const CashDepositForm: React.FC = () => {
  const methods = useForm<CashDepositFormRequest>();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isLoading = useRef<boolean>(false);
  const isLoadingPayments = useRef<boolean>(false);
  const [cash, setCash] = useState<Cash>({} as Cash);
  const [payments, setPayments] = useState<PaymentShortInfo[]>([]);
  const [salesmen, setSalesmen] = useState<Salesman[]>([]);
  const salesmanId = useWatch({ control: methods.control, name: 'salesman_id' });
  const amount = useWatch({ control: methods.control, name: 'amount' });
  const paymentId = useWatch({ control: methods.control, name: 'payment_id' });

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getCash(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setCash(data);
      methods.setValue('cash_id', data.id!);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }

    const { data: salesmenData, error: salesmenError } = await getSalesmenList();
    if (salesmenError) {
      enqueueSnackbar(salesmenError, { variant: 'error' });
      return;
    } else if (salesmenData) {
      setSalesmen(salesmenData.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [recordId, enqueueSnackbar, methods]);

  const fetchPayments = useCallback(async () => {
    isLoadingPayments.current = true;
    const { data, error } = await getTodayPaymentsForSalesman(undefined, undefined, undefined, undefined, { salesman_id: salesmanId });

    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setPayments(data.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }

    isLoadingPayments.current = false;
  }, [enqueueSnackbar, salesmanId]);

  useEffect(() => {
    if (!isLoading.current) {
      fetch();
    }
  }, [isLoading, fetch]);

  useEffect(() => {
    if (!isLoadingPayments.current && salesmanId) {
      fetchPayments();
    }
  }, [fetchPayments, salesmanId]);

  useEffect(() => {
    if (paymentId && payments.length > 0) {
      const currentPayment = payments.find(payment => payment.id === safeNumber(paymentId));
      methods.setValue('amount', safeNumber(currentPayment?.amount ?? '', 0));
    } else {
      methods.setValue('amount', 0);
    }
  }, [methods, paymentId, payments]);

  useEffect(() => {
    if (!salesmanId) {
      setPayments([]);
    }
    methods.setValue('payment_id', 0);
  }, [methods, salesmanId]);

  const onSubmit: SubmitHandler<CashDepositFormRequest> = async record => {
    const { error } = await createCashDeposit(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.admin.cashes.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={'Depósito'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Pago con Depósito">
            { isLoading.current || (!cash?.id && !isLoading.current) ? (
              <Spinner />
            ) : (
              <>
                <SectionDivider>
                  <Typography component="h6" variant="h6">{cash?.name ?? ''}</Typography>
                </SectionDivider>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                        Balance actual
                      <Typography
                        variant="h6"
                        display="block"
                        gutterBottom
                      >{formatNumber('money', safeNumber(cash.balance, 0))}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RightText>
                      <Typography>
                        Nuevo balance
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                        >{formatNumber('money', safeNumber(cash.balance, 0) + safeNumber(amount, 0))}</Typography>
                      </Typography>
                    </RightText>
                  </Grid>
                </Grid>
                <SectionDivider>
                  <Typography component="h6" variant="h6">Depósito</Typography>
                </SectionDivider>
                <FormTextField name="amount" label="Monto" disabled />
                <FormSelectField
                  name="salesman_id"
                  label="Gestor de Cobro"
                  options={salesmen.map(sm => ({ label: getPersonShortName(sm), value: sm.id?.toString() ?? '' }))}
                />
                { salesmanId && (
                  <>
                    { isLoadingPayments.current ? (
                      <Spinner />
                    ) : (
                      <FormSelectField
                        name="payment_id"
                        label="Abono"
                        options={payments.map(payment => ({ label: `${payment.amount} - ${payment.customer_name} - ${formatDate(moment(payment.created_at), 'time')}`, value: payment.id?.toString() ?? '' }))}
                      />
                    )}
                  </>
                )}
                <FormTextAreaField name="transaction_comments" label="Comentarios" />
              </>
            )}
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default CashDepositForm;
