import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';

import { getMiscExpenseList } from '../../../api/dailyOperations';
import { MiscExpense, miscExpenseKindOptions } from '../../../api/models/MiscExpense';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onMiscExpenseSelect?: (user?: MiscExpense) => void;
}

const MiscExpensesList: React.FC<Props> = ({ inModal, onMiscExpenseSelect }) => {
  const [searchParams] = useSearchParams();
  const [miscExpenses, setMiscExpenses] = useState<MiscExpense[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['Fecha', 'Tipo de gasto', 'Concepto', <AlignedColumn align="right" value="Monto" />];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getMiscExpenseList(currentPage, perPage, searchValue === '' ? undefined : searchValue);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setMiscExpenses(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, searchValue]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.dailyOperations.miscExpenses.list, currentPage, perPage), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal]);

  useEffect(() => {
    if (miscExpenses.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, miscExpenses.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'date', 'kind', 'concept', 'amount'] as Array<keyof MiscExpense>;
    const renderers: RenderersListType[] = [
      {
        field: 'amount',
        renderer: value => <NumericColumn value={value} format="money"  />,
      },
      {
        field: 'kind',
        renderer: value => <>{miscExpenseKindOptions.find(me => me.value === value)?.label}</>,
      },
    ];
    return getTableRows<MiscExpense>(miscExpenses, objectFields, renderers);
  }, [miscExpenses]);

  const handleRowSelect = (rowId: string) => {
    if (!onMiscExpenseSelect) {
      return;
    }

    onMiscExpenseSelect(miscExpenses.find(u => u?.id?.toString() === rowId.toString()));
  };

  const content = () => (
    <>
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Gastos Varios"
            formPageUrl={inModal ? '' : routes.dailyOperations.miscExpenses.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            noAddButton={inModal}
            selectable={inModal}
            onRowSelect={rowId => handleRowSelect(rowId)}
            searchPlaceholder="Fecha o concepto"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </>
  );

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Gastos Varios" />
          {content()}
        </MainLayout>
      )}
    </>
  );
};

export default MiscExpensesList;
