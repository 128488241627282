import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  getCash, createCashTransfer, getCashList,
} from '../../../api/admin';
import { Cash,  CashTransferFormRequest } from '../../../api/models/Cash';
import routes from '../../../common/routes';
import { formatNumber, safeNumber } from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormSelectField from '../../shared/FormSelectField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';
import Spinner from '../../shared/Spinner';
import { RightText } from '../../shared/StyledText';


const ActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 1em;
`;

const bcHistory = [{
  label: 'Cajas',
  url: routes.admin.cashes.list,
}];

const CashTransferForm: React.FC = () => {
  const methods = useForm<CashTransferFormRequest>();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isLoading = useRef<boolean>(false);
  const [cash, setCash] = useState<Cash>({} as Cash);
  // const [toCash, setToCash] = useState<Cash>({} as Cash);
  const [cashes, setCashes] = useState<Cash[]>([]);
  const amount = useWatch({ control: methods.control, name: 'amount' });
  const toCashId = useWatch({ control: methods.control, name: 'to_cash_id' });

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getCash(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setCash(data);
      methods.setValue('from_cash_id', data.id!);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }

    const { data: cashesData, error: cashesError } = await getCashList();
    if (cashesError) {
      enqueueSnackbar(cashesError, { variant: 'error' });
      return;
    } else if (cashesData) {
      setCashes(cashesData.records.filter(c => c.id?.toString() !== recordId?.toString()));
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [recordId, enqueueSnackbar, methods]);

  useEffect(() => {
    if (!isLoading.current) {
      fetch();
    }
  }, [isLoading, fetch]);

  const onSubmit: SubmitHandler<CashTransferFormRequest> = async record => {
    const { error } = await createCashTransfer(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.admin.cashes.list);
    }
  };

  const shouldDisableSave = () => {
    return toCashId === undefined
      || toCashId === null
      || toCashId === 0
      || safeNumber(amount, 0) <= 0;
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={'Transferencia a caja'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Transferencia de efectivo">
            { isLoading.current || (!cash?.id && !isLoading.current) ? (
              <Spinner />
            ) : (
              <>
                <SectionDivider>
                  <Typography component="h6" variant="h6">{cash?.name ?? ''}</Typography>
                </SectionDivider>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      Balance actual
                      <Typography
                        variant="h6"
                        display="block"
                        gutterBottom
                      >{formatNumber('money', safeNumber(cash.balance, 0))}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RightText>
                      <Typography>
                        Nuevo balance
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                        >{formatNumber('money', safeNumber(cash.balance, 0) - safeNumber(amount, 0))}</Typography>
                      </Typography>
                    </RightText>
                  </Grid>
                </Grid>
                <SectionDivider>
                  <Typography component="h6" variant="h6">Transferencia de efectivo</Typography>
                </SectionDivider>
                <FormTextField name="amount" label="Monto" />
                <FormSelectField
                  name="to_cash_id"
                  label="Caja de destino"
                  options={cashes.map(c => ({
                    label: `${c.name} (${formatNumber('money', safeNumber(c.balance, 0))})`,
                    value: c.id?.toString() ?? '',
                  }))}
                />
                <FormTextAreaField name="transaction_comments" label="Comentarios" />
              </>
            )}
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
                Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
                disabled={shouldDisableSave()}
              >
                Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default CashTransferForm;
