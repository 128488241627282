import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import {
  CreateOrUpdateOneRequestType,
  GetListRequestType,
  GetOneRequestType,
  ResponseWithPagination,
} from './models/BaeModel';
import { EmployeeSalaryPayment } from './models/EmployeeSalaryPayment';
import { InternalLoan } from './models/InternalLoan';
import { InternalLoanPayment } from './models/InternalLoanPayment';
import { MonthlyClosingSalary } from './models/MonthlyClosingSalary';
import { getUrlWithPagination, PERSONNEL_ROUTES } from './routes';

// InternalLoans
const getInternalLoanList: GetListRequestType<InternalLoan> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<InternalLoan[]>>(getUrlWithPagination(PERSONNEL_ROUTES.internal_loans.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createInternalLoan: CreateOrUpdateOneRequestType<InternalLoan> = async record => {
  try {
    const response = await api.post<InternalLoan>(PERSONNEL_ROUTES.internal_loans.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateInternalLoan: CreateOrUpdateOneRequestType<InternalLoan> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<InternalLoan>(
      PERSONNEL_ROUTES.internal_loans.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getInternalLoan: GetOneRequestType<InternalLoan> = async id => {
  try {
    const response = await api.get<InternalLoan>(PERSONNEL_ROUTES.internal_loans.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createInternalLoanPayment: CreateOrUpdateOneRequestType<InternalLoanPayment> = async record => {
  try {
    const response = await api.post<InternalLoanPayment>(PERSONNEL_ROUTES.internal_loans.payment.replace(':id', record.internal_loan_id!.toString()), record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << Internal Loans

// Monthly Closing Salary
const getMonthlyClosingSalaryList: GetListRequestType<MonthlyClosingSalary> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<MonthlyClosingSalary[]>>(getUrlWithPagination(PERSONNEL_ROUTES.monthly_closing_salaries.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createEmployeeSalaryPayment: CreateOrUpdateOneRequestType<EmployeeSalaryPayment> = async record => {
  try {
    const response = await api.post<EmployeeSalaryPayment>(PERSONNEL_ROUTES.monthly_closing_salaries.payment.replace(':id', record.monthly_closing_salary_id!.toString()), record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getMonthlyClosingSalary: GetOneRequestType<MonthlyClosingSalary> = async id => {
  try {
    const response = await api.get<MonthlyClosingSalary>(PERSONNEL_ROUTES.monthly_closing_salaries.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};
// << Monthly Closing Salary

export {
  getInternalLoan,
  getInternalLoanList,
  createInternalLoan,
  updateInternalLoan,
  createInternalLoanPayment,
  getMonthlyClosingSalaryList,
  createEmployeeSalaryPayment,
  getMonthlyClosingSalary,
};
