import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';

const InputWrapper = styled.div`
  padding: 0 0 1em 0;
`;

interface Props {
  name: string;
  label: string;
  // eslint-disable-next-line react/require-default-props
  readOnly?: boolean;
}

const FormSwitchField: React.FC<Props> = ({
  name,
  label,
  readOnly,
}) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        render={({ field }) => {
          return (
            <InputWrapper>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      color="primary"
                      disabled={readOnly}
                      { ...field }
                    />
                  }
                  label={label}
                />
              </FormGroup>
            </InputWrapper>
          );
        }}
        name={name}
        control={control}
      />
    </>
  );
};

export default FormSwitchField;
