import React from 'react';

import './App.css';
import DateFnsUtils from '@date-io/moment';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

import { COLORS } from './common/constants';
import AppRoutes from './components/routes/AppRoutes';
import { AuthProvider } from './contexts/useAuth';
import { TenantProvider, useTenant } from './contexts/useTenant';

// const theme = createTheme({
//   palette: {
//     error: {
//       main: COLORS.red,
//     },
//     primary: {
//       main: COLORS.bgGradientColorAverage,
//     },
//   },
// });

const Providers: React.FC = () => (
  <AuthProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AppRoutes />
    </MuiPickersUtilsProvider>
  </AuthProvider>
);

const App:React.FC = () => {
  const { currentTenantId } = useTenant();
  const jss = create({ ...jssPreset(), insertionPoint: 'jss-insertion-point' });


  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          error: {
            main: COLORS.red,
          },
          primary: {
            main: currentTenantId === 1 ? COLORS.bgGradientColorAverage : COLORS.clearDay,
          },
        },
      }),
    [currentTenantId],
  );

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <TenantProvider>
              <Providers/>
            </TenantProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
