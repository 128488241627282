import moment from 'moment/moment';

import { BaseModel } from './BaeModel';
import { MonthlyClosingSalary } from './MonthlyClosingSalary';
import { formatDate } from '../../common/utils';

interface MonthlyClosing extends BaseModel {
  total_in_payments: number;
  total_disbursed: number;
  total_interest: number;
  salesmen_gas_expenses: number;
  interests_paid_investment_accounts: number;
  phone_service_expenses: number;
  cloud_services_expenses: number;
  total_expenses: number;
  paid_in_month_salaries: number;
  net_profit: number;
  re_investment_amount: number;
  dividends: number;
  date: string;
  misc_expenses: number;
  last_monthly_closing_date?: string;
  period_start_date?: string;
  monthly_closing_salaries?: MonthlyClosingSalary[];
}

const defaultMonthlyClosingValues = {
  date: formatDate(moment(), 'api_date'),
  last_monthly_closing_date: formatDate(moment().add(-1, 'months'), 'api_date'),
  monthly_closing_salaries: [],
  period_start_date: formatDate(moment().add(-1, 'months'), 'api_date'),
};

export type { MonthlyClosing };

export { defaultMonthlyClosingValues };
