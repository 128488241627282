import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getCashList } from '../../../api/admin';
import {
  createMiscExpense, getMiscExpense, updateMiscExpense,
} from '../../../api/dailyOperations';
import { Cash } from '../../../api/models/Cash';
import {
  defaultMiscExpenseValues, MiscExpense, miscExpenseKindOptions,
} from '../../../api/models/MiscExpense';
import routes from '../../../common/routes';
import { formatNumber, safeNumber } from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormDateField from '../../shared/FormDateField';
import FormSelectField from '../../shared/FormSelectField';
import FormTextField from '../../shared/FormTextField';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Gastos Varios',
  url: routes.dailyOperations.miscExpenses.list,
}];

const MiscExpenseForm: React.FC = () => {
  const methods = useForm<MiscExpense>({ defaultValues: defaultMiscExpenseValues });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [cashes, setCashes] = useState<Cash[]>([]);
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);
  const isLoadingCashes = useRef<boolean>(false);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getMiscExpense(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('date', data.date);
      methods.setValue('amount', data.amount);
      methods.setValue('concept', data.concept);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  const fetchCashes = useCallback(async () => {
    isLoadingCashes.current = true;

    const { data: cashesData, error: cashesError } = await getCashList();
    if (cashesError) {
      enqueueSnackbar(cashesError, { variant: 'error' });
      return;
    } else if (cashesData) {
      setCashes(cashesData.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoadingCashes.current = false;
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  useEffect(() => {
    if (!isLoadingCashes.current) {
      fetchCashes();
    }
  }, [isLoading, fetchCashes]);

  const onSubmit: SubmitHandler<MiscExpense> = async record => {
    if (isEdit) {
      const { error } = await updateMiscExpense(record);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.dailyOperations.miscExpenses.list);
      }
      return;
    }

    const { error } = await createMiscExpense(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.dailyOperations.miscExpenses.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Gasto">
            <FormSelectField
              name="target_cash_id"
              label="Caja de destino"
              options={cashes.map(c => ({
                label: `${c.name} (${formatNumber('money', safeNumber(c.balance, 0))})`,
                value: c.id?.toString() ?? '',
              }))}
            />
            <FormSelectField
              name="kind"
              label="Tipo de gasto"
              options={miscExpenseKindOptions}
            />
            <FormDateField name="date" label="Fecha" todayButton/>
            <FormTextField
              name="amount"
              label="Monto"
              required
            />
            <FormTextField
              name="concept"
              label="Concepto"
              required
            />
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default MiscExpenseForm;
