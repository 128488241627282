import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MonthlyClosingSalariesForm from './MonthlyClosingSalariesForm';
import { defaultMonthlyClosingValues, MonthlyClosing } from '../../../api/models/MonthlyClosing';
import {
  createMonthlyClosing, getMonthlyClosing, updateMonthlyClosing, fetchCurrentClosingData,
} from '../../../api/reports';
import { RE_INVESTMENT_PERCENTAGE } from '../../../common/constants';
import routes from '../../../common/routes';
import {
  formatDate, formatNumber, safeNumber,
} from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormDateField from '../../shared/FormDateField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';
import {
  NegativeSummaryText, NeutralSummaryText, PositiveSummaryText, RightText, SummaryText,
} from '../../shared/StyledText';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Cierres Mensuales',
  url: routes.reports.monthly_closings.list,
}];

const MonthlyClosingForm: React.FC = () => {
  const methods = useForm<MonthlyClosing>({ defaultValues: defaultMonthlyClosingValues });
  const currentPeriodDate = useWatch({ control: methods.control, name: 'date' });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);
  const firstLoad = useRef<boolean>(true);
  const [netProfit, setNetProfit] = useState(0);
  const [actualProfit, setActualProfit] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [reInvestmentAmount, setReInvestmentAmount] = useState(0);
  const [dividends, setDividends] = useState(0);

  const salesmenGasExpenses = useWatch({ control: methods.control, name: 'salesmen_gas_expenses' });
  const interestsPaidInvestmentAccounts = useWatch({ control: methods.control, name: 'interests_paid_investment_accounts' });
  const phoneServiceExpenses = useWatch({ control: methods.control, name: 'phone_service_expenses' });
  const cloudServicesExpenses = useWatch({ control: methods.control, name: 'cloud_services_expenses' });
  const miscExpenses = useWatch({ control: methods.control, name: 'misc_expenses' });
  const totalInterest = useWatch({ control: methods.control, name: 'total_interest' });
  const paidInMonthlySalaries = useWatch({ control: methods.control, name: 'paid_in_month_salaries' });
  const periodStartDate = useWatch({ control: methods.control, name: 'period_start_date' });
  const periodEndDate = useWatch({ control: methods.control, name: 'date' });
  const totalInPayments = useWatch({ control: methods.control, name: 'total_in_payments' });
  const totalDisbursed = useWatch({ control: methods.control, name: 'total_disbursed' });
  const monthlyClosingSalaries = useWatch({ control: methods.control, name: 'monthly_closing_salaries' });

  const fetch = useCallback(async (date: string) => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = isEdit ? await getMonthlyClosing(recordId!) : await fetchCurrentClosingData(date);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      if (firstLoad.current) {
        methods.setValue('date', data.date);
      }
      methods.setValue('period_start_date', data.period_start_date);
      methods.setValue('total_in_payments', data.total_in_payments ?? 0);
      methods.setValue('total_disbursed', data.total_disbursed ?? 0);
      methods.setValue('total_interest', data.total_interest ?? 0);
      methods.setValue('salesmen_gas_expenses', data.salesmen_gas_expenses ?? 0);
      methods.setValue('interests_paid_investment_accounts', data.interests_paid_investment_accounts ?? 0);
      methods.setValue('phone_service_expenses', data.phone_service_expenses ?? 0);
      methods.setValue('cloud_services_expenses', data.cloud_services_expenses ?? 0);
      methods.setValue('misc_expenses', data.misc_expenses ?? 0);
      methods.setValue('total_expenses', data.total_expenses ?? 0);
      methods.setValue('paid_in_month_salaries', data.paid_in_month_salaries ?? 0);
      methods.setValue('net_profit', data.net_profit ?? 0);
      methods.setValue('re_investment_amount', data.re_investment_amount ?? 0);
      methods.setValue('dividends', data.dividends ?? 0);
      methods.setValue('monthly_closing_salaries', data.monthly_closing_salaries);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    firstLoad.current = false;
  }, [methods, recordId, enqueueSnackbar, isEdit]);

  useEffect(() => {
    if (!isLoading.current) {
      fetch(formatDate(moment(currentPeriodDate), 'api_date'));
    }
  }, [isLoading, fetch, currentPeriodDate]);

  useEffect(() => {
    let totalExpenses = 0;
    totalExpenses += safeNumber(salesmenGasExpenses, 0);
    totalExpenses += safeNumber(interestsPaidInvestmentAccounts, 0);
    totalExpenses += safeNumber(phoneServiceExpenses, 0);
    totalExpenses += safeNumber(cloudServicesExpenses, 0);
    totalExpenses += safeNumber(miscExpenses, 0);
    setTotalExpenses(totalExpenses);
    const newNetProfit = totalInterest - totalExpenses;
    setNetProfit(newNetProfit);
    const newActualProfit = newNetProfit - safeNumber(paidInMonthlySalaries, 0);
    setActualProfit(newActualProfit);
    if (actualProfit > 0) {
      const newReInvestmentAmount = newActualProfit * RE_INVESTMENT_PERCENTAGE;
      setReInvestmentAmount(newReInvestmentAmount);
      const newDividends = newActualProfit - newReInvestmentAmount;
      setDividends(newDividends);
    } else {
      setReInvestmentAmount(0);
      setDividends(0);
    }
  }, [
    cloudServicesExpenses,
    interestsPaidInvestmentAccounts,
    miscExpenses,
    paidInMonthlySalaries,
    phoneServiceExpenses,
    salesmenGasExpenses,
    totalInterest,
  ]);

  useEffect(() => {
    if (!monthlyClosingSalaries) {
      return;
    }

    let paid = 0;
    monthlyClosingSalaries.forEach(mcs => {
      paid += safeNumber(mcs.base_salary, 0);
      paid += safeNumber(mcs.commissions_amount, 0);
      paid -= safeNumber(mcs.deductions_amount, 0);
    });
    methods.setValue('paid_in_month_salaries', paid);
  }, [methods, monthlyClosingSalaries]);

  const onSubmit: SubmitHandler<MonthlyClosing> = async record => {
    const monthlyClosing = {
      ...record,
      dividends,
      net_profit: netProfit,
      re_investment_amount: reInvestmentAmount,
      total_expenses: totalExpenses,
    } as MonthlyClosing;

    if (isEdit) {
      const { error } = await updateMonthlyClosing(monthlyClosing);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.reports.monthly_closings.list);
      }
      return;
    }

    const { error } = await createMonthlyClosing(monthlyClosing);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.reports.monthly_closings.list);
    }
  };

  console.log(monthlyClosingSalaries);

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Cierre Mensual" wide>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormDateField name="period_start_date" label="Inicio del periodo" disabled/>
              </Grid>
              <Grid item xs={6}>
                <FormDateField name="date" label="Fin del periodo" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormTextField
                  name="total_in_payments"
                  label="Total cobrado"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="total_disbursed"
                  label="Total desembolsado"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormTextField
                  name="total_interest"
                  label="Total generado"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="salesmen_gas_expenses"
                  label="Total gastos de gasolina"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormTextField
                  name="interests_paid_investment_accounts"
                  label="Intereses cuentas de inversión"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="phone_service_expenses"
                  label="Gastos en servicios de telefonía"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormTextField
                  name="cloud_services_expenses"
                  label="Gastos en servicios en la nube"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="misc_expenses"
                  label="Gastos varios"
                  required
                />
              </Grid>
            </Grid>

            <SectionDivider>
              <Typography component="h6" variant="h6">
                Salarios del mes
                &nbsp;
                {formatDate(moment(periodStartDate), 'api_date')}
                &nbsp;-&nbsp;
                {formatDate(moment(periodEndDate), 'api_date')}
              </Typography>
            </SectionDivider>
            <MonthlyClosingSalariesForm />

            <SectionDivider>
              <Typography component="h6" variant="h6">
                Resumen del Cierre
                &nbsp;
                {formatDate(moment(periodStartDate), 'api_date')}
                &nbsp;-&nbsp;
                {formatDate(moment(periodEndDate), 'api_date')}
              </Typography>
            </SectionDivider>
            <>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SummaryText>Total cobrado</SummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <SummaryText>{formatNumber('money', totalInPayments)}</SummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} >
                  <SummaryText>Total desembolsado</SummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <SummaryText>{formatNumber('money', totalDisbursed)}</SummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SummaryText>Total generado</SummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <SummaryText>{formatNumber('money', totalInterest)}</SummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NegativeSummaryText>Total gastos de gasolina</NegativeSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NegativeSummaryText>{formatNumber('money', salesmenGasExpenses)}</NegativeSummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NegativeSummaryText>Intereses cuentas de inversión</NegativeSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NegativeSummaryText>{formatNumber('money', interestsPaidInvestmentAccounts)}</NegativeSummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NegativeSummaryText>Gastos en servicios de telefonía</NegativeSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NegativeSummaryText>{formatNumber('money', phoneServiceExpenses)}</NegativeSummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NegativeSummaryText>Gastos en servicios en la nube</NegativeSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NegativeSummaryText>{formatNumber('money', cloudServicesExpenses)}</NegativeSummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NegativeSummaryText>Gastos varios</NegativeSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NegativeSummaryText>{formatNumber('money', miscExpenses)}</NegativeSummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NegativeSummaryText>Total gastos</NegativeSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NegativeSummaryText>{formatNumber('money', totalExpenses)}</NegativeSummaryText>
                  </RightText>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  { netProfit >= 0 ? (
                    <PositiveSummaryText>Total excedentes</PositiveSummaryText>
                  ) : (
                    <NegativeSummaryText>Total excedentes</NegativeSummaryText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    { netProfit >= 0 ? (
                      <PositiveSummaryText>{formatNumber('money', netProfit)}</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>{formatNumber('money', netProfit)}</NegativeSummaryText>
                    )}
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NeutralSummaryText>Total en salarios</NeutralSummaryText>
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    <NeutralSummaryText>{formatNumber('money', paidInMonthlySalaries)}</NeutralSummaryText>
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  { actualProfit >= 0 ? (
                    <PositiveSummaryText>Saldo del periodo</PositiveSummaryText>
                  ) : (
                    <NegativeSummaryText>Saldo del periodo</NegativeSummaryText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    { actualProfit >= 0 ? (
                      <PositiveSummaryText>{formatNumber('money', actualProfit)}</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>{formatNumber('money', actualProfit)}</NegativeSummaryText>
                    )}
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  { actualProfit >= 0 ? (
                    <PositiveSummaryText>Reinversión</PositiveSummaryText>
                  ) : (
                    <NegativeSummaryText>Reinversión</NegativeSummaryText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    { actualProfit >= 0 ? (
                      <PositiveSummaryText>{formatNumber('money', reInvestmentAmount)}</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>{formatNumber('money', reInvestmentAmount)}</NegativeSummaryText>
                    )}
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  { actualProfit >= 0 ? (
                    <PositiveSummaryText>Dividendos totales</PositiveSummaryText>
                  ) : (
                    <NegativeSummaryText>Dividendos totales</NegativeSummaryText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    { actualProfit >= 0 ? (
                      <PositiveSummaryText>{formatNumber('money', dividends)}</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>{formatNumber('money', dividends)}</NegativeSummaryText>
                    )}
                  </RightText>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  { actualProfit >= 0 ? (
                    <PositiveSummaryText>Dividendos Aldo Dávila</PositiveSummaryText>
                  ) : (
                    <NegativeSummaryText>Dividendos Aldo Dávila</NegativeSummaryText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    { actualProfit >= 0 ? (
                      <PositiveSummaryText>{formatNumber('money', dividends / 2)}</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>{formatNumber('money', dividends / 2)}</NegativeSummaryText>
                    )}
                  </RightText>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  { actualProfit >= 0 ? (
                    <PositiveSummaryText>Dividendos Leydi Nájar</PositiveSummaryText>
                  ) : (
                    <NegativeSummaryText>Dividendos Leydi Nájar</NegativeSummaryText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RightText>
                    { actualProfit >= 0 ? (
                      <PositiveSummaryText>{formatNumber('money', dividends / 2)}</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>{formatNumber('money', dividends / 2)}</NegativeSummaryText>
                    )}
                  </RightText>
                </Grid>
              </Grid>
            </>

            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
                Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
                Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default MonthlyClosingForm;
