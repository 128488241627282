import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useFormContext, useWatch } from 'react-hook-form';

import { MonthlyClosingSalary } from '../../../api/models/MonthlyClosingSalary';
import { formatNumber, safeNumber } from '../../../common/utils';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import { RightText, SummaryText } from '../../shared/StyledText';

const MonthlyClosingSalariesForm: React.FC = () => {
  const { control } = useFormContext();
  const monthlyClosingSalaries: MonthlyClosingSalary[] = useWatch({ control: control, name: 'monthly_closing_salaries' });
  return (
    <div>
      {monthlyClosingSalaries.map((mcs, idx) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <SummaryText fontSize={16}>{mcs.employee_name}</SummaryText>
            </Grid>
            <Grid item xs={4}>
              <SummaryText fontSize={16}>{mcs.employee_role}</SummaryText>
            </Grid>
            <Grid item xs={4}>
              <SummaryText fontSize={16}>
                <RightText>
                  {formatNumber('money', safeNumber(mcs.base_salary, 0) + safeNumber(mcs.commissions_amount, 0) - safeNumber(mcs.deductions_amount, 0)) }
                </RightText>
              </SummaryText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormTextField
                name={`monthly_closing_salaries.${idx}.base_salary`}
                label="Salario base"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name={`monthly_closing_salaries.${idx}.commissions_amount`}
                label="Comisiones"
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormTextField
                name={`monthly_closing_salaries.${idx}.deductions_amount`}
                label="Deducciones"
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextAreaField
                name={`monthly_closing_salaries.${idx}.deductions_description`}
                label="Descripción deducciones"
              />
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default MonthlyClosingSalariesForm;
