import styled from 'styled-components';

const SummaryText = styled.div<{fontSize?: number}>`
    font-weight: bold;
    font-size: ${props => props.fontSize === undefined ? 24 : props.fontSize}px;
`;

const RightText = styled.div`
  text-align: right;
`;

const PositiveSummaryText = styled(SummaryText)`
    color: darkgreen;
`;

const NegativeSummaryText = styled(SummaryText)`
    color: #830303;
`;

const NeutralSummaryText = styled(SummaryText)`
    color: #01196e;
`;

export {
  RightText,
  PositiveSummaryText,
  NegativeSummaryText,
  NeutralSummaryText,
  SummaryText,
};
