import moment from 'moment';

import { BaseModel } from './BaeModel';
import { BillCountHelper, buildBillCountHelper } from './BillCountHelper';
import { formatDate } from '../../common/utils';

interface SalesmanReconciliation extends BaseModel {
  salesman_id: number;
  date: string;
  start_amount: number;
  collected_amount: number;
  additional_amount_received: number;
  conciliation_difference: number;
  end_amount: number;
  starting_comments: string;
  ending_comments: string;
  conciliated: boolean;
  voided: boolean;
  disbursed_amount: number;
  starting_bill_count_helper?: BillCountHelper
  ending_bill_count_helper?: BillCountHelper
  total_in_cash_deposits: number;
}

const defaultSalesmanReconciliationValues: Partial<SalesmanReconciliation> = {
  date: formatDate(moment(), 'api_date'),
  end_amount: 0,
  ending_bill_count_helper: buildBillCountHelper(),
  start_amount: 0,
  starting_bill_count_helper: buildBillCountHelper(),
  starting_comments: '',
};

export type { SalesmanReconciliation };

export { defaultSalesmanReconciliationValues };
