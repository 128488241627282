import moment from 'moment';

import { BaseModel } from './BaeModel';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';

interface EmployeeSalaryPayment extends BaseModel {
  employee_id: number;
  monthly_closing_salary_id: number;
  date: string;
  comments: string;
  amount: number;
}

const defaultEmployeeSalaryPaymentValues = {
  date: moment().format(DISPLAY_DATE_FORMAT),
  employee_id: 0,
  monthly_closing_salary_id: 0,
};

export type { EmployeeSalaryPayment };

export { defaultEmployeeSalaryPaymentValues };
