import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';

import { getInvestmentAccountList } from '../../../api/admin';
import { InvestmentAccount } from '../../../api/models/InvestmentAccount';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import DateColumn from '../../shared/list_view_column_renderers/DateColumn';
import ListActionsColumn from '../../shared/list_view_column_renderers/ListActionsColumn';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onInvestmentAccountSelect?: (investmentAccount?: InvestmentAccount) => void;
}

const InvestmentAccountList: React.FC<Props> = ({ inModal, onInvestmentAccountSelect }) => {
  const [searchParams] = useSearchParams();
  const [investmentAccounts, setInvestmentAccounts] = useState<InvestmentAccount[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['Dueño de la cuenta',
    'Fecha de inicio',
    'Fecha fin',
    <AlignedColumn value="Capital base" align="right" />,
    <AlignedColumn value="Intereses generados" align="right" />,
    <AlignedColumn value="Intereses pagados" align="right" />,
    <AlignedColumn value="Intereses pendientes" align="right" />,
  ];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getInvestmentAccountList(currentPage, perPage, searchValue === '' ? undefined : searchValue);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setInvestmentAccounts(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, searchValue]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.admin.investment_accounts.list, currentPage, perPage), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal]);

  useEffect(() => {
    if (investmentAccounts.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, investmentAccounts.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = [
      'id',
      'owner_full_name',
      'start_date',
      'scheduled_end_date',
      'base_balance',
      'interests_to_date',
      'interests_paid',
      'pending_interests',
    ] as Array<keyof InvestmentAccount>;
    const renderers: RenderersListType[] = [
      {
        field: 'start_date',
        renderer: value => <DateColumn value={value} displayFormat="display_date" />,
      },
      {
        field: 'scheduled_end_date',
        renderer: value => <DateColumn value={value} displayFormat="display_date" />,
      },
      {
        field: 'base_balance',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: 'interests_to_date',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: 'interests_paid',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: 'pending_interests',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: '_actions',
        renderer: record => <ListActionsColumn actions={[
          {
            label: 'Pagar intereses',
            onClick: record => navigate(routes.admin.investment_accounts.payment.replace(':id', record.id!.toString())),
          },
          {
            label: 'Finalización anticipada',
            onClick: record => navigate(routes.admin.investment_accounts.finalize.replace(':id', record.id!.toString())),
          },
          {
            label: 'Detalles',
            onClick: record => navigate(routes.admin.investment_accounts.details.replace(':id', record.id!.toString())),
          },
        ]} record={record} />,
      },
    ];
    return getTableRows<InvestmentAccount>(investmentAccounts, objectFields, renderers, true);
  }, [investmentAccounts, navigate]);

  const handleRowSelect = (rowId: string) => {
    if (!onInvestmentAccountSelect) {
      return;
    }

    onInvestmentAccountSelect(investmentAccounts.find(u => u?.id?.toString() === rowId.toString()));
  };

  const content = () => (
    <>
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Cuenta de inversión"
            formPageUrl={inModal ? '' : routes.admin.investment_accounts.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            hasActions
            noAddButton={inModal}
            selectable={inModal}
            onRowSelect={rowId => handleRowSelect(rowId)}
            searchPlaceholder="Nombre"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </>
  );

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Cuentas de inversión" />
          {content()}
        </MainLayout>
      )}
    </>
  );
};

export default InvestmentAccountList;
