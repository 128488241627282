import axios, { AxiosError } from 'axios';

import { authTokenKey } from '../contexts/useAuth';

const BASE_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  //baseURL: process.env.REACT_APP_API_URL ?? BASE_URL,
  baseURL: BASE_URL,
  withCredentials: false,
});

api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
api.defaults.headers.common.crossDomain = true;
api.defaults.headers.post['Content-Type'] = 'application/json';

api.interceptors.request.use(
  config => {
    // Do something before request is sent
    // eslint-disable-next-line
    // @ts-expect-error
    config.headers = {
      Authorization: localStorage.getItem(authTokenKey) ?? '',
    };
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// eslint-disable-next-line no-unused-vars
const successResponse: <T>(data: T) => ({ data: T }) = data => ({ data: data });

const errorResponse = (error: AxiosError) => {
  let message = (error?.response?.data as { errors: string[] })?.errors?.join('\n')
    ?? (error?.response?.data as { exception: string })?.exception
    ?? error.message;

  if (error?.response?.status === 404) {
    message = 'Registro no encontrado';
  }

  return { error: message };
};

export { api, BASE_URL, successResponse, errorResponse };
