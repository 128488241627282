import moment from 'moment';

import { BaseModel } from './BaeModel';
import { formatDate } from '../../common/utils';

interface MiscExpense extends BaseModel {
  date: string;                 // Expense date
  concept: string;              // Expense concept description
  amount: number;               // Expense amount
  kind: string;
  target_cash_id: number;
}

const defaultMiscExpenseValues = {
  date: formatDate(moment(), 'api_date'),
  kind: 'other',
  salesman_id: 0,
  target_cash_id: 0,
};

const miscExpenseKindOptions = [
  {
    label: 'Gasto variado',
    value: 'other',
  },
  {
    label: 'Servicios en la nube',
    value: 'cloud_services',
  },
  {
    label: 'Servicios telefónicos',
    value: 'phone_services',
  },
  {
    label: 'Pago de dividendos',
    value: 'dividends',
  },
];

export type { MiscExpense };

export { defaultMiscExpenseValues, miscExpenseKindOptions };
