import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { MonthlyClosingSalary } from '../../../api/models/MonthlyClosingSalary';
import { getMonthlyClosingSalaryList } from '../../../api/personnel';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import DateColumn from '../../shared/list_view_column_renderers/DateColumn';
import ListActionButton from '../../shared/list_view_column_renderers/ListActionButton';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

const ButtonGroup = styled.div`
  button {
    margin: 0 4px;
  }
`;

interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onMonthlyClosingSalarySelect?: (monthlyClosingSalary?: MonthlyClosingSalary) => void;
}

const MonthlyClosingSalariesList: React.FC<Props> = ({ inModal, onMonthlyClosingSalarySelect }) => {
  const [searchParams] = useSearchParams();
  const [monthlyClosingSalaries, setMonthlyClosingSalaries] = useState<MonthlyClosingSalary[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['Periodo', 'Colaborador', <AlignedColumn align="right" value="Monto" />, <AlignedColumn align="right" value="Saldo" />];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getMonthlyClosingSalaryList(currentPage, perPage, searchValue === '' ? undefined : searchValue);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setMonthlyClosingSalaries(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, searchValue]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.personnel.monthly_closing_salaries.list, currentPage, perPage), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal]);

  useEffect(() => {
    if (monthlyClosingSalaries.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, monthlyClosingSalaries.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'period', 'employee_name', 'net_salary', 'pending_balance'] as Array<keyof MonthlyClosingSalary>;
    const renderers: RenderersListType[] = [
      {
        field: 'created_at',
        renderer: value => <DateColumn value={value} displayFormat="display_date"  />,
      },
      {
        field: 'net_salary',
        renderer: value => <NumericColumn value={value} format="money"  />,
      },
      {
        field: 'pending_balance',
        renderer: value => <NumericColumn value={value} format="money"  />,
      },
      {
        field: '_actions',
        renderer: record => <ButtonGroup>
          <ListActionButton
            value={record}
            label='Abono'
            onClick={record => navigate(routes.personnel.monthly_closing_salaries.payment.replace(':id', record.id!.toString()))}
          />
        </ButtonGroup>,
      },
    ];
    return getTableRows<MonthlyClosingSalary>(monthlyClosingSalaries, objectFields, renderers, true);
  }, [monthlyClosingSalaries, navigate]);

  const handleRowSelect = (rowId: string) => {
    if (!onMonthlyClosingSalarySelect) {
      return;
    }

    onMonthlyClosingSalarySelect(monthlyClosingSalaries.find(u => u?.id?.toString() === rowId.toString()));
  };

  const content = () => (
    <>
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName=""
            formPageUrl={inModal ? '' : routes.personnel.internal_loans.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            hasActions
            noAddButton
            selectable={inModal}
            onRowSelect={rowId => handleRowSelect(rowId)}
            searchPlaceholder="Fecha o concepto"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </>
  );

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Salarios mensuales" />
          {content()}
        </MainLayout>
      )}
    </>
  );
};

export default MonthlyClosingSalariesList;
