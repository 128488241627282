import { api } from './api';
import { AUTH_ROUTES } from './routes';

// eslint-disable-next-line no-unused-vars
const login: (email: string, password: string) => Promise<{token?: string, error?: string}> = async (email, password) => {
  const data = { email, password };
  if (data.email === undefined || data.email === '') {
    return { error: 'Dirección de correo requerida' };
  } else if (data.password === undefined || data.password === '') {
    return { error: 'Contraseña requerida' };
  }
  try {
    const response = await api.post(AUTH_ROUTES.login, data);
    return { token: response.data.token };
  } catch (error) {
    const errorResponse = (error as { response: { data: { error: string }} });
    let errorMessage = errorResponse.response.data.error;
    if (errorMessage === 'unauthorized') {
      errorMessage = 'Usuario o contraseña inválido';
    }
    return { error: errorMessage };
  }
};

export { login as loginRequest };
