import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  getCash, getSalesmenList, createCashWithdrawal,
} from '../../../api/admin';
import { Cash,  CashWithdrawalFormRequest } from '../../../api/models/Cash';
import { getPersonShortName } from '../../../api/models/Person';
import { Salesman } from '../../../api/models/Salesman';
import routes from '../../../common/routes';
import { formatNumber, safeNumber } from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormSelectField from '../../shared/FormSelectField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';
import Spinner from '../../shared/Spinner';
import { RightText } from '../../shared/StyledText';


const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Cajas',
  url: routes.admin.cashes.list,
}];

const TransferToSalesmanForm: React.FC = () => {
  const methods = useForm<CashWithdrawalFormRequest>();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isLoading = useRef<boolean>(false);
  const [cash, setCash] = useState<Cash>({} as Cash);
  const [salesmen, setSalesmen] = useState<Salesman[]>([]);
  const salesmanId = useWatch({ control: methods.control, name: 'salesman_id' });
  const amount = useWatch({ control: methods.control, name: 'amount' });

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getCash(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setCash(data);
      methods.setValue('cash_id', data.id!);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }

    const { data: salesmenData, error: salesmenError } = await getSalesmenList();
    if (salesmenError) {
      enqueueSnackbar(salesmenError, { variant: 'error' });
      return;
    } else if (salesmenData) {
      setSalesmen(salesmenData.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [recordId, enqueueSnackbar, methods]);

  useEffect(() => {
    if (!isLoading.current) {
      fetch();
    }
  }, [isLoading, fetch]);

  const onSubmit: SubmitHandler<CashWithdrawalFormRequest> = async record => {
    const { error } = await createCashWithdrawal(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.admin.cashes.list);
    }
  };

  const shouldDisableSave = () => {
    return salesmanId === undefined
      || salesmanId === null
      || salesmanId === 0
      || safeNumber(amount, 0) <= 0;
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={'Transferencia a gestor'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Transferencia de efectivo">
            { isLoading.current || (!cash?.id && !isLoading.current) ? (
              <Spinner />
            ) : (
              <>
                <SectionDivider>
                  <Typography component="h6" variant="h6">{cash?.name ?? ''}</Typography>
                </SectionDivider>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                        Balance actual
                      <Typography
                        variant="h6"
                        display="block"
                        gutterBottom
                      >{formatNumber('money', safeNumber(cash.balance, 0))}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RightText>
                      <Typography>
                        Nuevo balance
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                        >{formatNumber('money', safeNumber(cash.balance, 0) - safeNumber(amount, 0))}</Typography>
                      </Typography>
                    </RightText>
                  </Grid>
                </Grid>
                <SectionDivider>
                  <Typography component="h6" variant="h6">Retiro de efectivo</Typography>
                </SectionDivider>
                <FormTextField name="amount" label="Monto" />
                <FormSelectField
                  name="salesman_id"
                  label="Gestor de Cobro"
                  options={salesmen.map(sm => ({ label: getPersonShortName(sm), value: sm.id?.toString() ?? '' }))}
                  clearButton
                />
                <FormTextAreaField name="transaction_comments" label="Comentarios" />
              </>
            )}
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
                disabled={shouldDisableSave()}
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default TransferToSalesmanForm;
