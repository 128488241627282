import { BaseModel } from './BaeModel';

interface InternalLoanPayment extends BaseModel {
  internal_loan_id: number;
  created_by: number;
  amount: number;
  comments: string
}

const defaultInternalLoanPaymentValues = {
  internal_loan_id: 0,
};

export type { InternalLoanPayment };

export { defaultInternalLoanPaymentValues };
