import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  createInvestmentAccount, getCashList, getInvestmentAccount, updateInvestmentAccount,
} from '../../../api/admin';
import { Cash } from '../../../api/models/Cash';
import { Customer, defaultCustomerValues } from '../../../api/models/Customer';
import { defaultInvestmentAccountValues, InvestmentAccount } from '../../../api/models/InvestmentAccount';
import { API_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../common/constants';
import routes from '../../../common/routes';
import { formatNumber, safeNumber } from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import CustomerSelectModal from '../../shared/CustomerSelectModal';
import FormDateField from '../../shared/FormDateField';
import FormRadioGroupField from '../../shared/FormRadioGroupField';
import FormSelectField from '../../shared/FormSelectField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const SelectClientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const bcHistory = [{
  label: 'Cuentas de inversión',
  url: routes.admin.investment_accounts.list,
}];

const InvestmentAccountForm: React.FC = () => {
  const methods = useForm<InvestmentAccount>({ defaultValues: defaultInvestmentAccountValues });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [cashes, setCashes] = useState<Cash[]>([]);
  const customerIdNumber = useWatch({ control: methods.control, name: 'customer.id_card_number' });
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);
  const isLoadingCashes = useRef<boolean>(false);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getInvestmentAccount(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('customer_id', data.customer_id);
      methods.setValue('monthly_interest_rate', data.monthly_interest_rate);
      methods.setValue('term_in_months', data.term_in_months);
      methods.setValue('start_date', data.start_date);
      methods.setValue('scheduled_end_date', data.scheduled_end_date);
      methods.setValue('actual_end_date', data.actual_end_date);
      methods.setValue('scheduled_term_in_days', data.scheduled_term_in_days);
      methods.setValue('actual_term_in_days', data.actual_term_in_days);
      methods.setValue('interest_amount_per_day', data.interest_amount_per_day);
      methods.setValue('status', data.status);
      methods.setValue('comments', data.comments);
      methods.setValue('ending_comments', data.ending_comments);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }

    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);


  const fetchCashes = useCallback(async () => {
    isLoadingCashes.current = true;

    const { data: cashesData, error: cashesError } = await getCashList();
    if (cashesError) {
      enqueueSnackbar(cashesError, { variant: 'error' });
      return;
    } else if (cashesData) {
      setCashes(cashesData.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoadingCashes.current = false;
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  useEffect(() => {
    if (!isLoadingCashes.current) {
      fetchCashes();
      console.log('loading cashes');
    }
  }, [isLoading, fetchCashes]);

  useEffect(() => {
    if (customerIdNumber) {
      try {
        const match = customerIdNumber.match(/^(\d{3})-(\d{6})-.*/);
        if (match) {
          const dob = match[2];
          const date_of_birth = moment(dob, 'DDMMyyyy').format(DISPLAY_DATE_FORMAT);
          methods.setValue('customer.date_of_birth', date_of_birth);
        }
      } catch {
        console.log('Número de cédula no válido');
      }
    }
  }, [customerIdNumber, methods]);

  const onSubmit: SubmitHandler<InvestmentAccount> = async record => {
    if (isEdit) {
      const { error } = await updateInvestmentAccount(record);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.admin.investment_accounts.list);
      }
      return;
    }

    const { error } = await createInvestmentAccount(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.admin.investment_accounts.list);
    }
  };

  const onCustomerChange = (customer?: Customer) => {
    if (customer) {
      methods.setValue('customer_id', customer.id!);
      methods.setValue('customer', { ...customer, date_of_birth: moment(customer.date_of_birth, API_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) });
    } else {
      methods.setValue('customer_id', 0);
      methods.setValue('customer', { ...defaultCustomerValues } as Customer);
    }
  };

  const handleModalClose = (customer: Customer | undefined) => {
    setShowModal(false);
    onCustomerChange(customer);
  };

  return (
    <MainLayout>
      <CustomerSelectModal open={showModal} onClose={customer => handleModalClose(customer)} />
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Cuenta de inversión" wide>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormDateField name="start_date" label="Fecha de Apertura" required todayButton />
              </Grid>
              <Grid item xs={4}>
                <FormSelectField
                  name="target_cash_id"
                  label="Caja de destino"
                  options={cashes.map(c => ({
                    label: `${c.name} (${formatNumber('money', safeNumber(c.balance, 0))})`,
                    value: c.id?.toString() ?? '',
                  }))}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectClientContainer>
                  <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>Seleccionar Cliente</Button>
                  <IconButton color="primary" onClick={() => onCustomerChange(undefined)}><CancelIcon /></IconButton>
                </SelectClientContainer>
              </Grid>
            </Grid>

            <SectionDivider>
              <Typography component="h6" variant="h6">Datos del Cliente</Typography>
            </SectionDivider>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.first_name_1"
                  label="Primer Nombre"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.first_name_2"
                  label="Segundo Nombre"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.lastname_1"
                  label="Primer Apellido"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.lastname_2"
                  label="Segundo Apellido"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormRadioGroupField name="customer.id_card_type" label="Tipo de Identificación" options={[
                  { label: 'Cédula', value: 'id_card' },
                  { label: 'Pasaporte', value: 'passport' },
                ]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.id_card_number"
                  label="Número de Identificación"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="customer.nationality"
                  label="Nacionalidad"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormRadioGroupField name="customer.gender" label="Sexo" options={[
                  { label: 'M', value: 'male' },
                  { label: 'F', value: 'female' },
                ]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormRadioGroupField name="customer.home_ownership_status" label="Vivienda" options={[
                  { label: 'Propia', value: 'own_home' },
                  { label: 'Familiar', value: 'family_owned' },
                  { label: 'Alquila', value: 'rent' },
                ]} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormDateField name="customer.date_of_birth" label="Fecha de Nacimiento" required />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.academic_status"
                  label="Nivel Académico"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextAreaField
                  name="customer.home_address"
                  label="Dirección Domiciliar"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.city"
                  label="Departamento"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.municipality"
                  label="Municipio"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.phone_number"
                  label="Teléfono del Domicilio"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.cellphone_number"
                  label="Celular"
                  required
                />
              </Grid>
            </Grid>

            <SectionDivider>
              <Typography component="h6" variant="h6">Datos de la cuenta</Typography>
            </SectionDivider>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="base_balance"
                  label="Capital Base"
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="monthly_interest_rate"
                  label="Tasa de Interés Mensual"
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="term_in_months"
                  label="Plazo (en meses)"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextAreaField
                  name="comments"
                  label="Comentarios"
                  required
                />
              </Grid>
            </Grid>

            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default InvestmentAccountForm;
