import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  getSalesman, createSalesmanReconciliation, updateSalesmanReconciliation,
} from '../../../api/admin';
import {
  // BillCountHelper,
  BillCountHelperDetail,
  // buildBillCountHelper,
} from '../../../api/models/BillCountHelper';
import { getPersonFullName } from '../../../api/models/Person';
import { Salesman } from '../../../api/models/Salesman';
import {
  defaultSalesmanReconciliationValues,
  SalesmanReconciliation,
} from '../../../api/models/SalesmanReconciliation';
import routes from '../../../common/routes';
import {
  formatDate, formatNumber, safeNumber,
  // formatNumber
} from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormBillCountHelperFieldGroup from '../../shared/FormBillCountHelperFieldGroup';
import FormDateField from '../../shared/FormDateField';
import FormSwitchField from '../../shared/FormSwitchField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';
import Spinner from '../../shared/Spinner';
import {
  NegativeSummaryText, PositiveSummaryText, RightText,
} from '../../shared/StyledText';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

// const CountHelperDetailTotal = styled.div`
//   font-weight: bold;
//   text-align: right;
//   margin-top: 20px;
// `;

const bcHistory = [{
  label: 'Arqueo de Gestor',
  url: routes.admin.salesmen.list,
}];

const SalesmanConciliationForm: React.FC = () => {
  const methods = useForm<SalesmanReconciliation>({ defaultValues: defaultSalesmanReconciliationValues });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const [isEdit, setIsEdit] = useState(false);
  const isLoading = useRef<boolean>(false);
  const [salesman, setSalesman] = useState<Salesman>({} as Salesman);
  // const [startingBillCountHelper, setStartingBillCountHelper] = useState<BillCountHelper>(buildBillCountHelper());
  const startingBillCountHelper = useWatch({ control: methods.control, name: 'starting_bill_count_helper' });
  const startingBillCountHelperDetails = useWatch({ control: methods.control, name: 'starting_bill_count_helper.bill_count_helper_details' });
  const endingBillCountHelper = useWatch({ control: methods.control, name: 'ending_bill_count_helper' });
  const endingBillCountHelperDetails = useWatch({ control: methods.control, name: 'ending_bill_count_helper.bill_count_helper_details' });
  const startAmount = useWatch({ control: methods.control, name: 'start_amount' });
  const endAmount = useWatch({ control: methods.control, name: 'end_amount' });
  const collectedAmount = useWatch({ control: methods.control, name: 'collected_amount' });
  const disbursedAmount = useWatch({ control: methods.control, name: 'disbursed_amount' });
  const totalInCashDeposits = useWatch({ control: methods.control, name: 'total_in_cash_deposits' });
  const additionalAmountReceived = useWatch({ control: methods.control, name: 'additional_amount_received' });
  const [conciliationDisplayDate, setConciliationDisplayDate] = useState(formatDate(moment(), 'display_date'));
  const [useStartingBillCountHelper, setUseStartingBillCountHelper] = useState(false);
  const [useEndingBillCountHelper, setUseEndingBillCountHelper] = useState(false);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getSalesman(recordId!, { include_cash_deposits: true });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setSalesman(data);
      methods.setValue('salesman_id', data.id ?? 0);
      methods.setValue('total_in_cash_deposits', data.total_in_cash_deposits ?? 0);
      if (data.current_reconciliation) {
        const sr = data.current_reconciliation;
        setIsEdit(true);
        setConciliationDisplayDate(formatDate(moment(sr.date), 'display_date'));
        methods.setValue('id', sr.id);
        methods.setValue('date', sr.date);
        methods.setValue('start_amount', sr.start_amount);
        methods.setValue('collected_amount', sr.collected_amount);
        methods.setValue('additional_amount_received', sr.additional_amount_received);
        methods.setValue('conciliation_difference', sr.conciliation_difference);
        methods.setValue('end_amount', sr.end_amount);
        methods.setValue('starting_comments', sr.starting_comments);
        methods.setValue('ending_comments', sr.ending_comments);
        methods.setValue('conciliated', true);
        methods.setValue('starting_bill_count_helper', sr.starting_bill_count_helper);
        methods.setValue('ending_bill_count_helper', sr.ending_bill_count_helper ?? defaultSalesmanReconciliationValues.ending_bill_count_helper);
        methods.setValue('disbursed_amount', sr.disbursed_amount);
      }
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (!salesman.id && !isLoading.current) {
      fetch();
    }
  }, [isLoading, fetch, salesman.id]);

  const getBCHDValue = useCallback((bchd: BillCountHelperDetail) => {
    return bchd.value * bchd.count * (bchd.is_usd ? startingBillCountHelper?.usd_exchange_rate ?? 1 : 1);
  }, [startingBillCountHelper?.usd_exchange_rate]);

  useEffect(() => {
    if (useStartingBillCountHelper && !isEdit) {
      let total = 0;
      let totalUSD = 0;
      startingBillCountHelperDetails?.forEach(bchd => {
        total += getBCHDValue(bchd);
        if (bchd.is_usd) {
          totalUSD += bchd.value * bchd.count;
        }
      });

      methods.setValue('start_amount', total);
      methods.setValue('starting_bill_count_helper.total', total);
      methods.setValue('starting_bill_count_helper.total_usd', totalUSD);
    }
  }, [
    isEdit,
    startingBillCountHelperDetails,
    methods,
    getBCHDValue,
    useStartingBillCountHelper,
  ]);

  useEffect(() => {
    if (useEndingBillCountHelper) {
      let total = 0;
      let totalUSD = 0;
      endingBillCountHelperDetails?.forEach(bchd => {
        total += getBCHDValue(bchd);
        if (bchd.is_usd) {
          totalUSD += bchd.value * bchd.count;
        }
      });

      methods.setValue('end_amount', total);
      methods.setValue('ending_bill_count_helper.total', total);
      methods.setValue('ending_bill_count_helper.total_usd', totalUSD);
    }
  }, [endingBillCountHelperDetails, methods, getBCHDValue, useStartingBillCountHelper]);

  const getConciliationTitle = () => {
    if (!salesman?.current_reconciliation) {
      return `Despachar Gestor - ${conciliationDisplayDate}`;
    }
    return `Arqueo - ${conciliationDisplayDate}`;
  };

  const getTotalStartAmount = () => (
    safeNumber(startAmount, 0) + safeNumber(additionalAmountReceived, 0)
  );

  const getExpectedEndAmount = () => (
    getTotalStartAmount() + safeNumber(collectedAmount, 0) - safeNumber(disbursedAmount, 0) - safeNumber(totalInCashDeposits, 0)
  );

  const getEndAmountDiff = () => endAmount - getExpectedEndAmount();

  const onSubmit: SubmitHandler<SalesmanReconciliation> = async record => {
    // console.log('Recod', record);
    // return;
    if (isEdit) {
      const updateRecord = { ...record } as SalesmanReconciliation;
      updateRecord.conciliation_difference = getEndAmountDiff();
      const { error } = await updateSalesmanReconciliation(updateRecord);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.admin.salesmen.list);
      }
      return;
    }

    const newRecord = { ...record };
    if (useStartingBillCountHelper) {
      newRecord.starting_bill_count_helper = startingBillCountHelper;
    }
    const { error } = await createSalesmanReconciliation(newRecord);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.admin.salesmen.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Arqueo de Gestor">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SectionDivider>
                  <Typography component="h6" variant="h6">Gestor</Typography>
                </SectionDivider>
              </Grid>
              {
                !salesman.id ? (
                  <Grid item xs={12}>
                    <Spinner />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <Typography>
                        Gestor
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                        >{getPersonFullName(salesman)}</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>
                        Teléfono
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                        >{salesman.cellphone_number}</Typography>
                      </Typography>
                    </Grid>
                  </>
                )
              }
              <Grid item xs={12}>
                <SectionDivider>
                  <Typography component="h6" variant="h6">{getConciliationTitle()}</Typography>
                </SectionDivider>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormDateField
                  name="date"
                  label="Fecha"
                  disabled={isEdit}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="start_amount"
                  label="Entregado para el dia"
                  disabled={isEdit}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextAreaField name="starting_comments" label="Comentarios iniciales" />
              </Grid>
              <Grid item xs={12}>
                <SectionDivider>
                  <Typography component="caption" variant="h6">Contador de billetes - Despacho</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useStartingBillCountHelper}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUseStartingBillCountHelper(event.target.checked)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label=""
                  />
                </SectionDivider>
              </Grid>
              { useStartingBillCountHelper && (
                <FormBillCountHelperFieldGroup
                  bch={startingBillCountHelper}
                  fieldName="starting_bill_count_helper"
                  readOnly={isEdit}
                />
              )}
              { salesman?.current_reconciliation && (
                <>
                  <Grid item xs={12}>
                    <SectionDivider>
                      <Typography component="caption" variant="h6">Contador de billetes - Arqueo</Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={useEndingBillCountHelper}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUseEndingBillCountHelper(event.target.checked)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </SectionDivider>
                  </Grid>
                  { useEndingBillCountHelper && <FormBillCountHelperFieldGroup bch={endingBillCountHelper} fieldName="ending_bill_count_helper" />}
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="additional_amount_received"
                      label="Transferido adicional"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="end_amount"
                      label="Total arqueado"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextAreaField name="ending_comments" label="Comentarios finales" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormSwitchField name="conciliated" label="Finalizar conciliación" />
                  </Grid>

                  <Grid item xs={6}>
                    <PositiveSummaryText>Total entregado</PositiveSummaryText>
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      <PositiveSummaryText>{formatNumber('money', getTotalStartAmount())}</PositiveSummaryText>
                    </RightText>
                  </Grid>
                  <Grid item xs={6}>
                    <NegativeSummaryText>Desembolsado</NegativeSummaryText>
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      <NegativeSummaryText>{formatNumber('money', safeNumber(disbursedAmount, 0))}</NegativeSummaryText>
                    </RightText>
                  </Grid>
                  <Grid item xs={6}>
                    <PositiveSummaryText>Recuperado</PositiveSummaryText>
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      <PositiveSummaryText>{formatNumber('money', safeNumber(collectedAmount, 0))}</PositiveSummaryText>
                    </RightText>
                  </Grid>
                  <Grid item xs={6}>
                    <PositiveSummaryText>Arqueo previsto</PositiveSummaryText>
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      <PositiveSummaryText>{formatNumber('money', getExpectedEndAmount())}</PositiveSummaryText>
                    </RightText>
                  </Grid>
                  <Grid item xs={6}>
                    <PositiveSummaryText>Total arqueo</PositiveSummaryText>
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      <PositiveSummaryText>{formatNumber('money', safeNumber(endAmount, 0))}</PositiveSummaryText>
                    </RightText>
                  </Grid>
                  <Grid item xs={6}>
                    <NegativeSummaryText>Total depositado</NegativeSummaryText>
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      <NegativeSummaryText>{formatNumber('money', safeNumber(totalInCashDeposits, 0))}</NegativeSummaryText>
                    </RightText>
                  </Grid>
                  <Grid item xs={6}>
                    { getEndAmountDiff() >= 0 ? (
                      <PositiveSummaryText>Diferencia</PositiveSummaryText>
                    ) : (
                      <NegativeSummaryText>Diferencia</NegativeSummaryText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <RightText>
                      { getEndAmountDiff() >= 0 ? (
                        <PositiveSummaryText>{formatNumber('money', getEndAmountDiff())}</PositiveSummaryText>
                      ) : (
                        <NegativeSummaryText>{formatNumber('money', getEndAmountDiff())}</NegativeSummaryText>
                      )}
                    </RightText>
                  </Grid>

                </>
              )}
            </Grid>
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
                Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
                Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default SalesmanConciliationForm;
