import moment from 'moment';

import { BaseModel } from './BaeModel';
import { Customer, defaultCustomerValues } from './Customer';
import { API_DATE_FORMAT } from '../../common/constants';

interface InvestmentAccount extends BaseModel {
  customer_id: number;
  customer?: Customer
  base_balance: number;
  monthly_interest_rate: number;
  term_in_months: number;
  start_date: string;
  scheduled_end_date: string;
  actual_end_date: string;
  scheduled_term_in_days: number;
  actual_term_in_days: number;
  interest_amount_per_day: number;
  status: string;
  comments: string;
  ending_comments: string;
  target_cash_id: number;
  interests_to_date: number;
  owner_full_name: string;
  pending_interests: number;
}

const defaultInvestmentAccountValues = {
  customer: defaultCustomerValues,
  customer_id: 0,
  monthly_interest_rate: 5,
  start_date: moment().format(API_DATE_FORMAT),
  target_cash_id: 0,
  term_in_months: 3,
};

export type { InvestmentAccount };

export { defaultInvestmentAccountValues };
