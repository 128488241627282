const routes = {
  admin: {
    cashes: {
      conciliate: '/admin/cashes/:id/conciliate',
      deposit: '/admin/cashes/:id/deposit',
      form: '/admin/cashes/:id',
      list: '/admin/cashes',
      transaction_history: '/admin/cashes/:id/transaction_history',
      transfer: '/admin/cashes/:id/transfer',
      withdrawal: '/admin/cashes/:id/withdrawal',
    },
    employees: {
      form: '/admin/employees/:id',
      list: '/admin/employees',
    },
    investment_accounts: {
      details: '/admin/investment_accounts/:id/details',
      finalize: '/admin/investment_accounts/:id/finalize',
      form: '/admin/investment_accounts/:id',
      list: '/admin/investment_accounts/',
      payment: '/admin/investment_accounts/:id/payment',
    },
    salesmen: {
      conciliate: '/admin/salesmen/:id/conciliate',
      form: '/admin/salesmen/:id',
      list: '/admin/salesmen',
    },
    users: {
      form: '/admin/users/:id',
      list: '/admin/users',
    },
  },
  customer: {
    form: '/customers/:id',
    list: '/customers',
    paymentForm: '/customers/:id/payment-form',
  },
  dailyOperations: {
    miscExpenses: {
      form: '/daily_operations/misc_expenses/:id',
      list: '/daily_operations/misc_expenses',
    },
    salesmenGasExpenses: {
      form: '/daily_operations/salesmen_gas_expenses/:id',
      list: '/daily_operations/salesmen_gas_expenses',
    },
  },
  dashboard: '/',
  loans: {
    dailyCustomerList: '/loans/daily-customer-list',
    loans: {
      form: '/loans/loans/:id',
      list: '/loans/loans',
    },
    quote: '/loans/quote',
  },
  login: '/login',
  payments: {
    form: '/payments/:id',
    list: '/payments',
  },
  personnel: {
    internal_loans: {
      form: '/personnel/internal_loans/:id',
      list: '/personnel/internal_loans',
      payment: '/personnel/internal_loans/:id/payment',
    },
    monthly_closing_salaries: {
      list: '/personnel/monthly_closing_salaries',
      payment: '/personnel/monthly_closing_salaries/:id/payment',
    },
  },
  reports: {
    monthly_closings: {
      form: '/reports/monthly_closings/:id',
      list: '/reports/monthly_closings',
    },
  },
};

export default routes;
