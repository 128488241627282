import moment from 'moment';

import { BaseModel } from './BaeModel';
import { formatDate } from '../../common/utils';

interface InternalLoan extends BaseModel {
  employee_id: number;
  created_by_id: number;
  applicant_name: string;
  applicant_id_card_number: string;
  applicant_phone_number: string;
  start_date: string;
  end_date: string;
  loan_purpose: string;
  comments: string;
  monthly_interest_fee: number;
  payment_status: string;
  amount: number;
  pending_balance: number;
}

const defaultInternalLoanValues = {
  employee_id: 0,
  end_date: formatDate(moment().add(1, 'month'), 'api_date'),
  start_date: formatDate(moment(), 'api_date'),
};

export type { InternalLoan };

export { defaultInternalLoanValues };
