import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getEmployeeList } from '../../../api/admin';
import { Employee } from '../../../api/models/Employee';
import { defaultInternalLoanValues, InternalLoan } from '../../../api/models/InternalLoan';
import { getPersonShortName } from '../../../api/models/Person';
import {
  createInternalLoan, getInternalLoan, updateInternalLoan,
} from '../../../api/personnel';
import routes from '../../../common/routes';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormDateField from '../../shared/FormDateField';
import FormSelectField from '../../shared/FormSelectField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Préstamos internos',
  url: routes.personnel.internal_loans.list,
}];

const InternalLoanForm: React.FC = () => {
  const methods = useForm<InternalLoan>({ defaultValues: defaultInternalLoanValues });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);
  const isLoadingEmployees = useRef<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const fetchSalesmen = useCallback(async () => {
    isLoadingEmployees.current = true;
    const { data, error } = await getEmployeeList(1, 100);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setEmployees(data.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoadingEmployees.current = false;
  }, [enqueueSnackbar]);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getInternalLoan(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('employee_id', data.employee_id);
      methods.setValue('applicant_name', data.applicant_name);
      methods.setValue('applicant_id_card_number', data.applicant_id_card_number);
      methods.setValue('applicant_phone_number', data.applicant_phone_number);
      methods.setValue('start_date', data.start_date);
      methods.setValue('end_date', data.end_date);
      methods.setValue('loan_purpose', data.loan_purpose);
      methods.setValue('comments', data.comments);
      methods.setValue('monthly_interest_fee', data.monthly_interest_fee);
      methods.setValue('payment_status', data.payment_status);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  useEffect(() => {
    if (employees.length === 0 && !isLoadingEmployees.current) {
      fetchSalesmen();
    }
  }, [employees, isLoadingEmployees, fetchSalesmen]);

  const onSubmit: SubmitHandler<InternalLoan> = async record => {
    if (isEdit) {
      const { error } = await updateInternalLoan(record);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.personnel.internal_loans.list);
      }
      return;
    }

    const { error } = await createInternalLoan(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.personnel.internal_loans.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Préstamo interno">
            <FormSelectField
              name="employee_id"
              label="Colaborador"
              options={employees.map(emp => ({ label: getPersonShortName(emp), value: emp.id?.toString() ?? '' }))}
              clearButton
            />
            <FormTextField
              name="applicant_name"
              label="Nombre del solicitante"
              required
            />
            <FormTextField
              name="applicant_id_card_number"
              label="Identificación del solicitante"
              required
            />
            <FormTextField
              name="applicant_phone_number"
              label="Teléfono del solicitante"
              required
            />
            <FormTextField
              name="amount"
              label="Cantidad solicitada"
              required
            />
            <FormDateField name="start_date" label="Fecha de inicio" todayButton/>
            <FormDateField name="end_date" label="Fecha fin" />
            <FormTextAreaField
              name="comments"
              label="Comentarios"
              required
            />
            <FormTextField
              name="monthly_interest_rate"
              label="Tasa de interés mensual"
              required
            />
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default InternalLoanForm;
